
function Firma() {
    return (
        <div className="container" >
            <div className='row'>
                <div className='col-12 col-md-8 col-lg-6 mx-auto'><br/>
                <strong className="custom-font-firma">Te esperamos</strong><br/>
                <strong className="custom-font">Edith y Delfino</strong>
                </div>
            </div><br/><br/>
        </div>
    );
}

export default Firma;