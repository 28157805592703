// src/components/ImageViewer.js
import React from 'react';

const ImageViewer = ({ images, currentIndex, onClose }) => {
  const handlePrev = () => {
    // Mostrar la imagen anterior
    // Si currentIndex es 0, mostrar la última imagen
  };

  const handleNext = () => {
    // Mostrar la siguiente imagen
    // Si currentIndex es el último índice, mostrar la primera imagen
  };

  return (
    <div className="image-viewer">
      <span className="close" onClick={onClose}>&times;</span>
      <div className="image-container">
        <img src={images[currentIndex].url} alt={images[currentIndex].alt} />
      </div>
      {/*<div className="controls">
        <button onClick={handlePrev}>Anterior</button>
        <button onClick={handleNext}>Siguiente</button>
      </div>*/}
    </div>
  );
};

export default ImageViewer;