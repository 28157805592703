import React from "react";

import fecha from '../img/fecha.png';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome
const Ceremonia = ({ gifSrc, altText }) => {
    return (
        <div class="container">
            <div className="row">
                <div className="col-12 col-md-8 col-lg-6 mx-auto p-0 my-0">
                    <div className="p-0 my-0  ">
                        <div >
                            <img src={gifSrc} alt={altText} width="80" />

                        </div>
                        <div className="col" >
                            <strong className="custom-font-text">CEREMONIA</strong>
                            {/*
                                <img src={fecha} className="img-fluid shadow-img" alt="Responsive" />
                            */}


                            <div className="fecha-boda">
                            <div className="divBlock">
                                <div className="dia col-4">
                                    <div className="linea"></div>
                                    <span>SÁBADO</span>
                                    <div className="linea"></div>
                                </div>
                                <div className="numero col-4">28</div>
                                <div className="hora col-4">
                                    <div className="linea"></div>
                                    <span> 13:00 H</span>
                                    <div className="linea"></div>
                                </div>
                                </div>
                                <div className="mes-ano">DE DICIEMBRE DE 2024</div>
                            </div>

                            <p className="custom-font-p">Te esperamos en Jardín "LA PIEDAD" <br />
                                Perú 8, San Nicolas, 69800 Tlaxiaco, Oax.</p>
                            <p className="custom-font-p"></p>

                        </div>




                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ceremonia;