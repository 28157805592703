import React, { useState } from 'react';
import ImageViewer from './ImageViewer'
import foto1 from '../img/DEYED.jpg'
import foto2 from '../img/playa3.jpg'
import foto3 from '../img/EYD.jpeg'

const Gallery = ({ images }) => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    const openImageViewer = (index) => {
        setSelectedImageIndex(index);
    };

    const closeImageViewer = () => {
        setSelectedImageIndex(null);
    };
    const fotos = [foto1, foto2, foto3]
    return (
        <div className="container">
            <strong className="custom-font-text">NOSOTROS</strong>
            <div className="row">
                <div className="col-12 col-md-8 col-lg-6 mx-auto">

                    <div className="col-6 image-container-nosotros p-1" onClick={() => openImageViewer(0)}>
                        <img src={foto1} className="img-fluid " />
                    </div>
                    <div className="col-6 image-container-nosotros p-1" onClick={() => openImageViewer(1)}>
                        <img src={foto2} className="img-fluid " />
                    </div>
                    <div className="col-12 image-container-nosotros p-1" onClick={() => openImageViewer(2)}>
                        <img src={foto3} className="img-fluid " />
                    </div>


                   



                    {selectedImageIndex !== null && (
                        <ImageViewer
                            images={images}
                            currentIndex={selectedImageIndex}
                            onClose={closeImageViewer}
                        />
                    )}
                </div>
            </div>
            <div class="separator">
                <div class="line"></div>
                <i class="fa-sharp fa-solid fa-heart icon"></i>
                <div class="line"></div>
            </div>
        </div>
    );
};

export default Gallery;