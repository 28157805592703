import React, { useState, useEffect } from 'react';
import TimeUnit from './timeunit';

const CuentaRegresiva = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);

    });


    const { days, hours, minutes, seconds } = timeLeft;

    return (

        <div className='container' >
            <div className='row'>
                <div className="col-12 col-md-8 col-lg-6 mx-auto p-0 my-0 centered-container" >
                    <div className='divHoras'>
                        <div className='col-2 divB' >
                            <TimeUnit label="Días" value={days} />
                        </div>
                        <div className='divEspacio' >  </div>
                        <div className='col-2 divB' >
                            <TimeUnit label="Horas" value={hours} />
                        </div>
                        <div className='divEspacio'>  </div>
                        <div className='col-2 divB' >
                            <TimeUnit label="Minutos" value={minutes} />
                        </div>
                        <div className='divEspacio'>  </div>
                        <div className='col-2 divB' >
                            <TimeUnit label="Segundos" value={seconds} />
                        </div>
                    </div>
                    {
                        /**
                         <div className='item mx-auto p-0 my-0'></div>
                    <div className='item mx-auto p-0 my-0' >
                        <TimeUnit label="Días" value={days} />
                    </div>
                    <div className='item mx-auto p-0 my-0'>
                        <TimeUnit label="Horas" value={hours} />
                    </div>
                    <div className='item mx-auto p-0 my-0'>
                        <TimeUnit label="Minutos" value={minutes} />
                    </div>
                    <div className='item mx-auto p-0 my-0'>
                        <TimeUnit label="Segundos" value={seconds} />
                    </div>
                    <div className='item mx-auto p-0 my-0'></div>
                         */
                    }




                </div>
            </div>
        </div>

    );
};

export default CuentaRegresiva;