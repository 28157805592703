
import React from 'react';
const EventLocationComponent = ({ event }) => {
    const openGoogleMaps = () => {
        const { lat, lon } = event.location;
        const url = `https://maps.app.goo.gl/8YG221bLCP4vYD3C7`;
        window.open(url, '_blank');
    };

    return (
        <div className='container'>
            <button className="button" onClick={openGoogleMaps}><i class="fas fa-map-marker-alt"></i> Ver ubicación</button>
            <div class="separator">
                <div class="line"></div>
                <i class="fa-sharp fa-solid fa-heart icon"></i>
                <div class="line"></div>
            </div>
        </div>


    );
};

export default EventLocationComponent;