import React, { useState } from 'react';
import {
    Container,
    TextField,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Button,
    Typography,
    Paper,
    MenuItem
} from '@mui/material';
import axios from 'axios';

const ConfirmacionAsistencia = () => {
    const [formData, setFormData] = useState({
        name: '',
        numAsistencia: '',
        attendance: '',
        selection: '',
        mensaje: '',
    });

    const [submitted, setSubmitted] = useState(false);
    const currencies = [
        {
            value: '1',
            label: '1',
        },
        {
            value: '2',
            label: '2',
        },
        {
            value: '3',
            label: '3',
        },
        {
            value: '4',
            label: '4',
        },
        {
            value: '5',
            label: '5',
        },
        {
            value: '6',
            label: '6',
        },
        {
            value: '7',
            label: '7',
        },
        {
            value: '8',
            label: '8',
        },
        {
            value: '9',
            label: '9',
        },

        {
            value: '10',
            label: '10',
        }

    ];
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("formData");
        console.log(formData);
        axios.post(`${process.env.REACT_APP_API_URL}/api/asistencia`, formData)
            .then(response => {
                console.log('Success:', response.data);
                setSubmitted(true);
            })
            .catch(error => {
                console.error('Error:', error);
            });



    };



    return (
        <div class="container">
            <div className="row">
                <div className="col-12 col-md-8 col-lg-6 mx-auto p-0 my-0">




                    <Container maxWidth="sm" sx={{ mt: 4, backgroundColor: 'transparent', }}>
                    <strong className="custom-font-text p-0 my-0">CONFIRMACIÓN DE ASISTENCIA</strong> <br/>
                    <span className='custom-font-p'>A partir de octubre, esta sección estará disponible para que confirmes tu asistencia. <br/> </span>
                        {/*
                            <strong className="custom-font-text p-0 my-0">CONFIRMACIÓN DE ASISTENCIA</strong>
                            <Paper sx={{ p: 4, mt: 2, backgroundColor: 'transparent' }}>

                                {!submitted ? (
                                    <form onSubmit={handleSubmit}>
                                        <FormControl component="fieldset" sx={{ mb: 2 }}>
                                            <p className='custom-font-p'>Por favor, confirma tu asistencia</p>
                                            <RadioGroup

                                                row
                                                name="attendance"
                                                value={formData.attendance}
                                                onChange={handleChange}
                                            >
                                                <FormControlLabel
                                                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '12px' } }}
                                                    value="si"
                                                    control={<Radio />}
                                                    label="Sí, Allí estare!"
                                                />
                                                <FormControlLabel
                                                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '12px' } }}
                                                    value="no"
                                                    control={<Radio />}
                                                    label="No podre asistir"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                        {formData.attendance === 'si' && (
                                            <div>
                                                <TextField
                                                    label="Nombre Completo"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    required
                                                    sx={{ mb: 2 }}
                                                />
                                                <TextField
                                                    id="outlined-select-currency"
                                                    name="numAsistencia"
                                                    select
                                                    fullWidth
                                                    label="Total de asistentes"
                                                    onChange={handleChange}
                                                    sx={{ mb: 2 }}
                                                >
                                                    {currencies.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                <TextField
                                                    label="Mensaje"
                                                    name="mensaje"
                                                    multiline
                                                    variant="outlined"
                                                    value={formData.mensaje}
                                                    onChange={handleChange}
                                                    fullWidth

                                                />


                                            </div>

                                        )}

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            sx={{ mt: 2, backgroundColor: '#999B84', }}
                                        >
                                            Enviar
                                        </Button>
                                    </form>
                                ) : (
                                    <div sx={{ textAlign: 'center', mt: 2 }}>
                                        <Typography variant="h5">
                                            ¡Gracias por confirmar tu asistencia, {formData.name}!
                                        </Typography>
                                        <Typography>
                                            Has indicado que {formData.attendance === 'si' ? 'asistirás' : 'no asistirás'} a la boda.
                                        </Typography>
                                    </div>
                                )}
                            </Paper>
 */}
                    </Container>
                    <div class="separator">
                        <div class="line"></div>
                        <i class="fa-sharp fa-solid fa-heart icon"></i>
                        <div class="line"></div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ConfirmacionAsistencia;