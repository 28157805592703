import React, { useRef, useEffect }  from 'react';
import ReactPlayer from 'react-player';
import cancion from '../assets/cancion.mp3'
const AudioPlayer = ({ play }) => {
  const audioRef = useRef(null);
 // const [isPlaying, setIsPlaying] = useState(false);

 /* const handlePlay = () => {
    audioRef.current.play();
    setIsPlaying(true);
  };

  const handlePause = () => {
    audioRef.current.pause();
    setIsPlaying(false);
  };*/

  useEffect(() => {
    if (play) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [play]);


  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-8 col-lg-6 mx-auto p-0 my-0">
          <audio controls ref={audioRef}>
            <source src={cancion} type="audio/mpeg" />
          </audio>
          {/*<div className="buttons">
            <button onClick={handlePlay} disabled={isPlaying}>Reproducir Música</button>
            <button onClick={handlePause} disabled={!isPlaying}>Detener Música</button>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;