
function Texto() {
    return (
        <div className="container" >
            <div className='row'>
                <div className='col-12 col-md-8 col-lg-6 mx-auto'>
                    <div>
                        <strong className='custom-font-text'>NOS CASAMOS</strong>

                    </div>
                    <div style={{marginLeft:'-30px'}}><br/>
                        <strong className='custom-font-nombres'>E y D</strong>
                    </div>
                    <div>
                        <strong className='custom-font-text'>!Estas Invitado!</strong>
                    </div>


                </div>
            </div>
            <div className='row'>
                <div className='col-12 col-md-8 col-lg-6 mx-auto  '>
                    <div className=' p-4 my-0'>
                        <p className='custom-font-p'> Con la Bendición de Dios y nuestros padres: <br />
                            Novia <br />
                            Domingo Santiago Bautista, <br /> Eusebia Bautista López  <br />
                            &
                            <br />
                            Novio

                            <br /> Bernardino Mendoza Maldonado, <br /> Alejandra Ortiz Silva.
                            <div class="separator">
                                <div class="line"></div>
                                <i class="fa-sharp fa-solid fa-heart icon"></i>
                                <div class="line"></div>
                            </div>
                            <br /> Nos encantaria que seas parte de este momento tan especial para nosotros. ¡Falta poco!
                        </p>
                    </div>



                </div>
            </div>



        </div>
    );
}

export default Texto;