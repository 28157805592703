import React from 'react';

const ItineraryItem = ({ time, title, icon }) => {
    return (
        <div className="container" >
            <div className='row'>
                <div className='col-12 col-md-8 col-lg-6 mx-auto'>
                    <div className="itinerary-item">
                        <div className="icon">{icon}</div>
                        <div className="details">
                            <div className="title">{title}</div>
                            <div className="time">{time}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ItineraryItem;