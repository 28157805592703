import React from 'react';

const TimeUnit = ({ label, value }) => {
    return (
        <div >

            <div className="time-value">{value}</div>
            <div className="time-label">{label}</div>
        </div>
    );
};

export default TimeUnit;