import React from "react";
import fondo from '../img/principal.jpg';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome
const Home = () => {
    return (
        <div class="container" >
            <div className="row" >
                <div className="col-12 col-md-8 col-lg-6 mx-auto p-0 my-0" >
                    <div className="p-0 my-0  "  >
                        <div className="image-container">
                            <img src={fondo} className="img-fluid shadow-img" alt="Responsive" />
                            <i class="fa-solid fa-chevron-down overlay-arrow "></i>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;